.side__buttons {
    width: 48px !important;
    height: 48px;
    border-radius: 1px !important;


}
.differences_page__side__icons{
    width: 48px!important;
    height: 48px!important;
    color:black!important;
    border: 0.5px solid #F90!important;
    background: #FFF9EF !important;
    border-radius: 0px 4px 4px 0px!important;
}