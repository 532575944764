.approvals_history{
    width:383px;
    max-height:88vh;
    border:0.5px solid #E6E6E6;
    border-radius: 4px;

}

.reviews_container{
    max-height:73vh;
    -ms-overflow-style:none;
    scrollbar-width:none;
    overflow: auto;
}

.reviews_container::-webkit-scrollbar{
    display: none;
}
.approval_history_heading{
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    font-style: normal;
}

.approval_history_header{
    height: 68px;
    background: #fff9ef;
    padding: 18px 16px;
}
.reviews_table_options{
    color: #808080;
    font-size: 14px;
    line-height: 20.02px;
}
.reviews_table_header{
    width: 382px;
    height: 49px;
    border-bottom: 0.5px solid #E6E6E6;
    padding-left: 16px;
    padding-top: 14px;
}

.each_approval_row{
    width: 381px;
    height: 49px;
    border-bottom: 0.5px solid #E6E6E6;
    padding-left: 16px;
    padding-top: 14px;
}
.single_approval_expand_style{
    width: 28px;
    height: 28px;
    margin-left: 36px!important;
    padding-bottom: 14px!important;
}
.single_approval_icon_style{
    width: 20px;
    height: 20px;
    color: #808080;
}

.approvals_history_version{
    font-size:14px;
    line-height:20.02px;
    color:#0070f0;
    text-decoration:underline;
    cursor: pointer;
}