.mainRowDataTable{
    background-color: white  !important;
    font-weight: bold  !important;
    border-collapse: separate  !important;
    border-spacing: 0 1px  !important;
    font-weight: 401  !important;
    table-layout: fixed;
    width: 100%;
}
.mainRowDataTableBody{
    background-color: #E5E5E5  !important;
    /*table-layout: fixed;*/
    /*width: inherit;*/
}
.mainRowDataTableBodyRow{
    background-color: #E5E5E5  !important;
    display: flex  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCell{
    justify-content: space-between  !important;
    /* background-color: white  !important; */
    padding-bottom: 0  !important;
    padding-right: 0  !important;
    padding-top: 0  !important;
    font-weight: 400  !important;
    width: 80%  !important;
    text-align: left  !important;
}
.mainRowDataTableBodyRowDataCellName{
    width: 40%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCellColon{
    width: 5%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCellValue{
    width: 55%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowIconCell{
    width: 20%  !important;
    /* background-color: white  !important; */
    padding: 0  !important;
    display: flex  !important;
    align-items: right  !important;
    flex-direction: row  !important;
    justify-content: flex-end  !important;
    align-items: center  !important;
    color: green  !important;
}

.expandableTableHeaderRowIconCellButton{
    padding: 0 !important;
}