.dialog__title {
    background-color: #E6F1FF !important;
}

.dialog__content {
    margin-top: 16px !important;
}

.environment {
    border-color: #808080 !important;
}

.editor__box {
    min-height: 270px !important;
}

.box__helper_text {
    font-size: 14px !important;
    color: dimgrey;
}

.editor {
    min-height: 263px !important;
}

.button__row {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 8px !important;
}

.switch__button {
    background: #ECECEC !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    color: #000000 !important;
    text-align: center !important;
    line-height: 10px !important;
    text-transform: none !important;
    font-weight: bold !important;
    font-family: Amazon Ember !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}


.button {
    color: #0070F0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 12px !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-transform: none !important;
    letter-spacing: 0.17px !important;
    background: white !important;
    border: white !important;
}

.button__disabled {
    color: grey !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 12px !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-transform: none !important;
    letter-spacing: 0.17px !important;
    background: white !important;
    border: white !important;
}