filter-review-icon{
    position: relative !important;
    display: inline-block !important;
}
.popup-filter-items{
    position: absolute !important;
}
.menu-item{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 0px !important;
}

.Cancel_Filter{
    width: 16px;
    height: 16px;
    margin-right: 15px;
}

.cancel-icon{
    margin-left: 13px !important;
}

.reviews_filter{
    overflow: auto;
    scrollbar-width:none;
    -ms-overflow-style:none;
}
.reviews_filter::-webkit-scrollbar{
    display: none;
}

.date_range_calendar .rdrDayNumber span{
    /*color: black;*/
    font-weight: 350;
}
.date_range_calendar .rdrMonthName{
    display: none;;
}
.date_range_calendar .rdrWeekDay{
    font-weight: 350;
    color: #1d2429;
}
.date_range_calendar .rdrDateDisplayItem input{
    font-weight: 350;
    color: #1d2429;
}
.date_range_calendar .rdrDefinedRangesWrapper{
    width: 102px;
}
.date_range_calendar .rdrInputRange{
    display: none;

}


.date_range_calendar .rdrStaticRangeLabel{
    padding: 10px 10px 10px 5px;
}
.date_range_calendar .rdrDefinedRangesWrapper {
    width: 40px;
    height: 100px;

}
.rdrDateRangePickerWrapper{
    display: flex;
    flex-direction: column;
}
.date_range_calendar .rdrStaticRanges {
    display: flex;
    flex-direction: row;

}
.date_range_calendar .rdrStaticRange {
    padding-left: 2px ;
    padding-right: 2px;
    border-top: 1px solid #EFF7FE;
}
.date_range_calendar .rdrInputRanges {
    display: flex;
    flex-direction: row;
}
.date_range_calendar .rdrDefinedRangesWrapper{
    border-right: #fff;
    height: 60px;
}

.rdrMonthsVertical{
    height: 255px;
}