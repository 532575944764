#dropdown-Nav {
    text-align:left;
    border-radius: 0.0rem;
}

#dropdown-Nav2 {
    text-align:left;
    border-radius: 0.0rem;
}

.gridWrapper {
    border-bottom: 1px solid grey;
}

.gridText {
    display: flex;
    justify-content: flex-start;
    border: 1px solid grey;
    padding-left: 1rem;
}