.local__icon {
    width: 19px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 15px !important;
    font-size: 13px !important;
    background-color: black;
    color: white;
}

.update__buttons {
    padding: 0;
}

.table__header {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 8px;
    padding-right: 8px;
}

.config__table_header {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.table {
    height: calc(88vh - 129px) !important;
}

.config__table {
    height: calc(88vh - 154px) !important;
}

.table__rows {
    height: calc(88vh - 193px) !important;
}

.config__table_rows {
    height: calc(88vh - 204px) !important;
}

.table__row {
    background-color: #FFFFFF;
}

.button{
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 20px;
    font-size: 12px;
    color: #000000;
    text-align: center;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
}

.config__button {
    margin-left: 8px !important;
    border-radius: 4px !important;
    border-color: #808080 !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.config__button__disabled {

    margin-left: 8px !important;
    border-radius: 4px !important;
    color: #7f7f7f !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;

}

.config__button_param {
    margin-right: 8px !important;
    margin-left: 8px !important;
    border-radius: 4px !important;
    border-color: #808080 !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.local__button {
    margin-left: 8px !important;
    border-radius: 4px !important;
    border-color: #DBDBDB !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.local__button_config {
    border-radius: 4px 0 0 4px !important;
    border-color: #B0B0B0 !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.override__button {
    border-left: 0;
    border-radius: 0 !important;
    border-color: #B0B0B0 !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.inherited__button {
    border-left: 0;
    border-radius: 0 4px 4px 0 !important;
    border-color: #B0B0B0 !important;
    color: black !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Amazon Ember !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 143% !important;
    letter-spacing: 0.17px !important;
    text-transform: none !important;
}

.button__selected {
    background-color: #E5F1FE !important;
}

.local__icon {
    width: 20px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 15px !important;
    font-size: 13px !important;
    background-color: black;
    color: white;
}

.override__icon {
    width: 20px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 15px !important;
    font-size: 13px !important;
    background-color: #9F492D;
    color: white;
}

.inherited__icon {
    width: 20px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px !important;
    font-size: 13px !important;
    background-color: #4700CC;
    color: white;
}

.search__icon {
    width: 24px;
    height: 24px;
    margin-left: 16px;
}

.updateButtons {
    padding: 0
}

.mainRowDataTable{
    background-color: white !important;
    font-weight: bold  !important;
    border-collapse: separate  !important;
    border-spacing: 0 1px  !important;
    font-weight: 401  !important;
    table-layout: fixed;
    width: 100%;
}
.mainRowDataTableBody{
    background-color: white !important;
    /*table-layout: fixed;*/
    /*width: inherit;*/
}
.mainRowDataTableBodyRow{
    background-color: white  !important;
    display: flex  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCell{
    justify-content: space-between  !important;
    /* background-color: white  !important; */
    padding-bottom: 0  !important;
    padding-right: 0  !important;
    padding-top: 0  !important;
    font-weight: 400  !important;
    width: 80%  !important;
    text-align: left  !important;
}
.mainRowDataTableBodyRowDataCellName{
    width: 40%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCellColon{
    width: 5%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowDataCellValue{
    width: 55%  !important;
    display: inline-block  !important;
    height: 100%  !important;
}
.mainRowDataTableBodyRowIconCell{
    width: 20%  !important;
    /* background-color: white  !important; */
    padding: 0  !important;
    display: flex  !important;
    align-items: right  !important;
    flex-direction: row  !important;
    justify-content: flex-end  !important;
    align-items: center  !important;
    color: green  !important;
}

.expandableTableHeaderRowIconCellButton{
    padding: 0 !important;
}

.textfield__disabled{
    background-color: #F2F8FF;
    border-radius: 4px;
}