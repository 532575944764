.main {
    width: 100%;
    height: calc(100vh - 60px);
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.left_column {
    height: 100%;
    width: 1440px !important;
    margin-left: auto;
    margin-right: auto;
}