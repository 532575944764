.tr {
    background-color: #E5E5E5;
    font-size: 0.6rem;
    font-weight: bold;
}

.tc {
    padding-bottom: 2px;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 0.7rem;
    font-weight: bold;
}

.textField {
    width: 100%;
    border-radius:0px;
    text-overflow:ellipsis;
    font-family: Amazon Ember;
    font-size : 0.8rem;
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: 400;
}

.form-control:focus{
    box-shadow: none;
}
