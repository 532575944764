div{
    font-family: Amazon Ember;
}
.card__data_count {
    color:  #000;

    font-size: 14px;
    font-style: normal;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
}
.card_data {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.4px;
}
.icon{
    height: 16px;
    width: 16px;
    color: #808080;
    align-self: stretch;
}
 .card_container:hover{
    border: 1.3px solid #0070F0;

}
.card_container.card_title{
    color: black;
}
.card_container:hover .card_title{
    color: #0070F0;
}

.grid_container{
    overflow: auto;
    scrollbar-width:none;
    -ms-overflow-style:none;
}

.grid_container::-webkit-scrollbar{
    display: none;
}
