.tenant__page {
    width: 1440px !important;
    /*height: 95vh !important;*/
}

.pipe__icon {
    stroke-width: 123;
}

.main__container {
    width: 1361px !important;
    padding-top: 26.8px;
    padding-left: 32px;
    gap: 32px;
}

.versions__container {
    width: 383px !important;
    height: 88vh !important;
}

.main__container_left {
    border-radius: 4px;
    width: 912px !important;
    height: 88vh !important;
    outline: #E6E6E6 solid 1px;
}

.main__container_right {

}

.top__card {
    background-color: #E5F1FE;
    padding: 8px;
}

.label__text {
    font-size: 14px !important;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.17px;
    color: #808080;
    padding-left: 14px;
    padding-top: 4px;
}

.pip{
    color: white;
    font-size: 14px !important;
    margin-left: 7px !important;
    margin-top: 4px !important;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 15px;
}

.label__text_config {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #808080;
    margin-top: -8px;
    padding-left: 16px;
    padding-bottom: 5px;
}

.form__field_1 {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 65px;
    margin-bottom: 8px !important;
    margin-right: 8px !important;
}

.form__field_2 {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 65px;
}

.form__field_3 {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 40px;
    margin-right: 8px !important;
}

.form__field_3_expanded {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 65px;
    margin-right: 8px !important;
}

.form__field_4 {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 40px;
}

.form__field_4_expanded {
    border-radius: 4px;
    background-color: white;
    width: 444px;
    height: 65px;
}

.none__menuitem {
    color: grey !important;
}

.dropdown {
    width: 420px;
    height: 24px;
    margin-left: 16px;
    padding-right: 0;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
}

.version__dropdown {
    outline: none;
    width: 100%;
    min-width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    border: 0;
}

.config__dropdown {
    outline: none;
    width: 100%;
    min-width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
}

.config__dropdown_disabled {
    outline: none;
    width: 100%;
    min-width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
}

.parent__textfield {
    border-radius: 4px;
    letter-spacing: 0.17px;
}

.parent__disabled {
    background-color: #F2F8FF;
    border-radius: 4px;
}

.select__adornment {
    padding: 3px !important;
}