
.differences__display__container{
    max-width: 648px!important;
    border-radius: 4px!important;
    margin-top: 16px !important;
    /*overflow: auto;*/
    /*scrollbar-width:none;*/
    /*-ms-overflow-style:none;*/
}
.differences__rows__container::-webkit-scrollbar{
    display: none;
}
/*631*/
.differences__rows__container{
    max-height: calc(100vh - 346px) !important;
    width: 648px!important;
    overflow: auto;
    scrollbar-width:none;
    -ms-overflow-style:none;

}

.left__display__version__status__container{

    width: 312px !important;
    height: 65px !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: 0.5px solid #E6E6E6 !important;
    margin: 8px 7px 7px 8px !important;
}
.no__of__differences__container{
    padding-right: 8px!important;
    padding-top: 8px!important;
}
.right__display__version__status__box{
    width: 312px!important;
    min-height: 65px!important;
    border-radius: 4px!important;
    padding-top: 6px!important;
    border: 0.5px solid #E6E6E6!important;
    margin: 8px 7px 7px 8px!important;
}
.arrow_icon_button{
    width: 15.2px!important;
    height: 13.79px!important;
    margin-right: 2px!important;
}
.down_arrow_icon_button{
    width: 15.2px!important;
    height: 13.79px!important;
    padding-left: 0px !important;
}
.down_arrow_icon{
    color: #757575!important;
    width: 24px!important;
    height: 24px!important;
}
.value_type{
    margin-left: 10px;
    width: 50px;
    height: 30px;
    border-radius: 4px;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.display_row_container{
    max-width: 646px;
    border-bottom: 1px solid #E6E6E6;
    height:inherit;
    min-height:50px;
    display: flex;
    align-items: center;
    border-left: none;
    border-right: none;
}
.key_in_row_container{
    width: 274px;
    min-height: 24px;
}
.down_arrow_icon_button :hover{
    cursor: default;
}
.down_arrow_icon :hover{
    cursor: default;
}
.dividerStyle{

    width: 1.5px!important;
    height: 49px!important;
    background: #E6E6E6!important;
    margin-top: 8px!important;
}
.arrow_icon{
    border-radius: 2px !important;
    background: #FF9900!important;
    width: 15.2px!important;
    height: 13.79px!important;
    color: #FFFFFF!important;
}
.box_header{
    color: #808080;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
}
.box_header_right_display{
    padding-left: 16px;
    color: #808080;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
}
.version__dropdown__list {
    max-height: 500px;
    overflow-y: auto;
}
.colors__info__container{
    font-size: 12px!important;
    line-height: 19.92px!important;
    letter-spacing: 0.4px!important;
    margin: 2px 8px 8px 8px!important;
}
.circle_style{
    width: 8px;
    height: 8px;
    border-radius: 100px;
    border: 0.5px solid #1F7A1F;
    background: #EBFAEB;
}

.differences_info_box{
    width: 312px !important;
    height: 65px!important;
    padding-left: 12px!important;
    padding-right: 3px!important;
    border-radius: 4px!important;
    border: 0.5px solid #E6E6E6!important;
    margin: 8px 9px 7px 0px!important;
}

.differences__panel div span{
    color: #808080;
}
