.input-container {
    position: relative;
    display: inline-block;
}

.dropdown__input{
    width: 240px;
    border-radius: 4px;
    border: 1px solid #0070F0;
    padding: 12px;
    height: 30px;
}
.dropdown__input ::placeholder{
    color: #D9D9D9;
}
.popup-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    background-color: #FFFFFF;
    margin-top: 0;
    font-size: 15px;
    font-weight: 400;
    z-index: 100;
}

.popup-dropdown ul {
    list-style: none;
    padding-left: 3px;
    margin: 0;
    border: 1px solid rgba(0,0,0,.25)

}

.popup-dropdown li {
    padding: 2px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup-dropdown li:hover {
    background-color: #E6F1FF;
}

.dropdown-list {
    max-height: 600px;
    overflow-y: auto;
}