.tenantContainer {
    /*border-right: 10px double gray;*/
    /*border-style: double;*/
    /*border-width: thick;*/
    width: inherit;
    table-layout: fixed;
    /*width: inherit;*/
}

.tenantRow {
    background-color: lightgray;
    font-weight: bold;
    height: 10px;
}

.jsoneditor-menu{
    background-color: #979797 !important;
    border-bottom: 0 !important;
}
jsoneditor-menu > button {
    color : black !important;
}

.jsoneditor-modes > button {
    color :  black !important;
}

.jsoneditor {
    border : 0 !important;
}
